import React from 'react'
import 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FaCrop, FaExpand } from 'react-icons/fa'

const ExpandableImage = ({ image, alt, height = '20rem', ...props }) => {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <div
      tw="w-screen my-4 overflow-hidden relative cursor-pointer transition-all"
      onClick={() => setExpanded(!expanded)}
      {...props}
    >
      <GatsbyImage
        image={image}
        alt={alt}
        style={{
          height: !expanded ? height : 'auto',
        }}
      />
      <div
        tw="absolute bottom-0 flex items-center justify-center uppercase text-white text-sm w-full py-2 font-semibold"
        style={{
          opacity: 0.6,
        }}
      >
        {!expanded ? (
          <>
            Show Full Size <FaExpand tw="ml-2" />
          </>
        ) : (
          <>
            Show Cropped <FaCrop tw="ml-2" />
          </>
        )}
      </div>
    </div>
  )
}

export default ExpandableImage
