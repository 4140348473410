import GATSBY_COMPILED_MDX from "/Users/ebemunk/proj/shaka-zone/app/src/articles/what-to-bring-on-a-hike-in-oahu/index.mdx";
function _EMOTION_STRINGIFIED_CSS_ERROR__() {
  return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
}
import React from 'react';
import {MDXProvider} from '@mdx-js/react';
import Layout from '../components/Layout';
import DesignSystem from '../components/DesignSystem';
import ExpandableImage from '../components/ExpandableImage';
import SEO from '../components/SEO';
import {jsx as ___EmotionJSX} from "@emotion/react";
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "s8h4ei",
  styles: "max-width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center"
} : {
  name: "knkpfx-Template",
  styles: "max-width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;label:Template;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "db9nhh",
  styles: "margin-top:0px"
} : {
  name: "11sbx2k-Template",
  styles: "margin-top:0px;label:Template;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "zigog8",
  styles: "display:flex;flex-direction:column;align-items:center"
} : {
  name: "qkuotz-Template",
  styles: "display:flex;flex-direction:column;align-items:center;label:Template;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1azakc",
  styles: "text-align:center"
} : {
  name: "3j2noa-Template",
  styles: "text-align:center;label:Template;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "s5rh90",
  styles: "display:flex;text-transform:uppercase;font-size:0.875rem;line-height:1.25rem;--tw-text-opacity:1;color:rgba(75, 85, 99, var(--tw-text-opacity));margin-bottom:0.5rem"
} : {
  name: "v234fp-Template",
  styles: "display:flex;text-transform:uppercase;font-size:0.875rem;line-height:1.25rem;--tw-text-opacity:1;color:rgba(75, 85, 99, var(--tw-text-opacity));margin-bottom:0.5rem;label:Template;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
function Template(_ref) {
  var data = _ref.data, children = _ref.children;
  var _data$mdx = data.mdx, frontmatter = _data$mdx.frontmatter, body = _data$mdx.body;
  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: frontmatter.title,
    description: frontmatter.summary,
    type: "article",
    canonical: "/articles/slow-roasted-pork-shoulder-recipe",
    image: frontmatter.thumbnail.childImageSharp.gatsbyImageData.images.fallback.src
  }), ___EmotionJSX("div", {
    css: _ref2
  }, ___EmotionJSX(ExpandableImage, {
    image: frontmatter.thumbnail.childImageSharp.gatsbyImageData,
    css: _ref3,
    alt: frontmatter.title
  }), ___EmotionJSX("article", {
    css: _ref4
  }, ___EmotionJSX(DesignSystem.H1, {
    css: _ref5
  }, frontmatter.title), ___EmotionJSX("div", {
    css: _ref6
  }), ___EmotionJSX(MDXProvider, {
    components: {
      p: DesignSystem.P,
      h1: DesignSystem.H1,
      h2: DesignSystem.H2,
      h3: DesignSystem.H3,
      ul: DesignSystem.UL,
      a: DesignSystem.A
    }
  }, children))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var query = "4025514874";
